// mixins
@mixin button-color($bg, $color: 'white') {
    background-color: $bg;
    color: $color;
}

@mixin box-shadow {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

@mixin border-span ($left: 0, $color: 'blue-warm-60v') {
    &:before {
        content: " ";
        position: absolute;
        border-left: 1px solid color($color);
        top: 10%;
        left: $left;
        height: 90%;
        margin-top: auto;
        margin-bottom: auto;
    }
}

@mixin list-items($direction: row) {
    display: flex;
    flex-flow: $direction wrap;
    list-style: none;
    gap: 12px;
    padding-inline-start: 0;

    li {
        position: relative;
    }
}

// helper clases
.text {
    &-center {
        text-align: center;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-color {
        &-red {
            color: color('secondary');
        }
    }
    &-italic {
        font-style: italic;
    }
    &-bold {
        font-weight: bold;
    }
}

.desktop {
    &-show {
        display: none;

        @include at-media('desktop') {
            display: inline;
        }
    }

    &-hide {
        display: inline;

        .usa-nav__primary & {
            display: list-item;
        }

        @include at-media('desktop') {
            display: none !important;

            .usa-nav__primary & {
                display: none;
            }
        }
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}

.full-width {
    width: 100%;
}