/*
  *******************
  ****** USWDS ******
  *******************
*/
.grid-container {
    max-width: 75rem;

    .usa-header & {
        .grid-row {
            align-items: center;
        }
    }

    @include at-media-max('desktop') {
        .usa-header & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.usa-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.usa-header {
    &--extended {
        .usa-logo {
            display: inline;
        }

        .usa-navbar {
            max-width: none;
            padding-left: 0;
        }

        .usa-nav__inner {
            margin-left: 0;
        }

        .usa-nav {
            border: none;
        }
    }
}

.usa-banner,
.usa-banner__inner {
    max-width: none;
    padding-left: 0;
}

.usa-prose {
    margin: 0 auto;

    &>p {
        max-width: none;
    }
}

.usa-tooltip {
    display: inline;
}

.usa-identifier {
    &__container {
        max-width: 75rem;
    }
}

.usa-footer {
    .grid-container {
        max-width: 75rem;
    }

    .grid-row {
        .desktop\:grid-col-fill {
            flex: 1 1 0%;
            width: auto;
            max-width: 100%;
            min-width: 1px;
        }

    }
}

/*
  *******************
  ****** PA11Y ******
  *******************
*/
// #main-content > section > div > div > div > div > div > a {
//     background-color: #0072ed;
// }

a {
    color: #0072ed;
}

#main-content>div>div>div>div>ul>button {
    color: #001731;
}

footer li>a {
    color: #0061c9;
}

#main-content>section>a {
    color: #0072ed;
}

img[alt='Logo'] {
    margin-left: 75px;
    width: 150px;
}

button {
    margin-top: 0px !important;
}

#touchpoints-form-1327b00b>div:nth-child(3)>header>div>div:nth-child(2)>p>a {
    background-color: #fff;
}

.usa-table td {
  text-align: left
}

.usa-table.full-width tr {
    td:first-child {
        width: 90%;
    }
    td:last-child {
        // text-align: center;
    }
    
} 